import NiceModal, { useModal } from "@ebay/nice-modal-react";
import CloseSvg from "./svg/CloseSvg";
import DiscordSvg from "./svg/DiscordSvg";
import TwitterSvg from "./svg/TwitterSvg";

const Content = () => {
  const modal = useModal();

  const onCloseFn = () => {
    modal.remove();
  };

  const onPlay = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`modal fade join-pop ${modal.visible ? "show" : ""}`}
      tabIndex={-1}
      id="staticBackdrop"
      aria-labelledby="staticBackdrop"
      aria-modal="true"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="star-ani">
            <div className="stars"></div>
            <div className="stars2"></div>
          </div>
          <button type="button" className="btn-close" onClick={onCloseFn}>
            <CloseSvg />
          </button>
          <div className="join-pop-content">
            <h2>Join our community</h2>
            <p>
              Stay update to date with our project announcements and unpdates!
            </p>
            <div className="joinbtn-row">
              <a
                href="https://discord.com/invite/crystalfun"
                className="joinbtn"
                target="_blank"
              >
                <DiscordSvg />
                Join Discord
              </a>
              <a
                href="https://twitter.com/playCrystalFun"
                className="joinbtn"
                target="_blank"
              >
                <TwitterSvg />
                Follow us on Twitter
              </a>
            </div>
            <img
              src="https://assets.crystalfun.io/frontend/images/joinpop-cover.png"
              className="joinpop-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NiceModal.create(Content);
