import Dot from "./Dot";
import styles from "./index.module.css";

function SpanLoading(props: any) {
  const { size = 16, color } = props;
  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        position: "relative",
        textAlign: "center",
        animation: `${styles.skRotate} 2.0s infinite linear`,
      }}
    >
      <Dot color={color} />
      <Dot color={color} isLast />
    </div>
  );
}

export default SpanLoading;
