import styles from "./index.module.css";

function Header(props: any) {
  const { onClose } = props;
  return (
    <div className={styles["chain-modal-header"]}>
      <div className={styles["modal-title"]}>Swith Network</div>
      <a
        className={styles["chain-pop-close"]}
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={onClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"></path>
        </svg>
      </a>
    </div>
  );
}

export default Header;
