function CloseSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.46 189.46">
      <path
        d="M100,89.12,18.41,7.53A7.69,7.69,0,0,0,7.5,18.38l0,0L89.12,100,7.53,181.59A7.69,7.69,0,0,0,18.38,192.5l0,0L100,110.88l81.59,81.59a7.69,7.69,0,0,0,10.91-10.85l0,0L110.88,100l81.59-81.59A7.69,7.69,0,0,0,181.62,7.5l0,0Z"
        transform="translate(-5.27 -5.27)"
      ></path>
    </svg>
  );
}
export default CloseSvg;
