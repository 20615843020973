import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@rainbow-me/rainbowkit/styles.css";
import NiceModal from "@ebay/nice-modal-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { socialLinkDic } from "./constance";
import LoadingScreen from "./components/LoadingScreen";
import SpanLoading from "./components/SpanLoading";

import "./i18n";
import Wallet from "./Wallet";

(window as any).socialLinkDic = socialLinkDic;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<SpanLoading />}>
      <Provider store={store}>
        <NiceModal.Provider>
          <BrowserRouter>
            <>
              <App />
              <ToastContainer />
            </>
          </BrowserRouter>
        </NiceModal.Provider>
      </Provider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
