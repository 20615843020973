import { useSignMessage, useSwitchChain } from "wagmi";
import useConnectThen from "./useConnectThen";
import useGetConnectSuccess from "./useGetConnectSuccess";
import { httpPost } from "@/utils/httpRequest";
import { TypeUser } from "@/store/types";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "@/store/features/playerSlice";
import { getDefaultChainId } from "@/utils";
import useWalletSign from "./useWalletSign";

function useLoginByWallet() {
  const params = useParams();
  const { signMessage } = useSignMessage();
  const { address } = useGetConnectSuccess();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { chains, switchChainAsync } = useSwitchChain();
  const signFn = useWalletSign();

  async function onLoginByWallet() {
    await onLogin();
  }

  const loginByWalletFn = useConnectThen(onLoginByWallet);

  async function onLogin() {
    const message = "CrystalLabs.Login." + new Date().getTime();
    signFn("CrystalLabs.Login.", async (signature: string) => {
      const reqData = {
        message,
        signature,
        address,
        code: params?.code ? params?.code : undefined
      };
      await switchChainAsync({ chainId: defaultChainId });
      const { user } = (await httpPost("/v1/users/loginByWallet", reqData, {
        cacheToken: true,
      })) as any;
      const tempData: TypeUser = {
        code: user?.code,
        address: user?.address,
        hasBindEmail: user?.hasBindEmail,
        activate: user?.activate,
        mother: user?.mother,
        platformsData: user?.platforms || {},
        username: user?.username,
      };
      console.log(user);
      dispatch(setUser(tempData));
      navigate("/user/wallet");
    });

    // 切到推荐的链
    const defaultChainId = getDefaultChainId();
    switchChainAsync({ chainId: 0xaa36a7 });
  }

  return loginByWalletFn;
}

export default useLoginByWallet;
