import styles from  './index.module.css';
import useResponsive from '../../hooks/useResponsive';

const Empty = (props: any) => {
  const isMobile = useResponsive()
  const { txt, type } = props;
  const style: any = !isMobile ? (type === 'game' ? {left: '25%'} : type === 'wallet' ? {left: '75%'} : null) : (type === 'game' ? {top: '25%'} : type === 'wallet' ? {top: '75%'} : null)
  return (
    <div className={styles["area-empty"]} style={style}>
      <img src="https://assets.crystalfun.io/frontend/assets/img/account/empty.png" className={styles["empty-icon"]} alt='empty-icon' />
      <span>{txt || 'No assets'}</span>
    </div>
  );
};

export default Empty;