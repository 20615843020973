export enum EnumWalletItem {
  okx = "okx",
  metamask = "metamask",
}

export const walletDic: any = {
  [EnumWalletItem.okx]: {
    img: "https://assets.outer.gg/frontend/img/common/okxIcon.png",
    desc: "OKX Wallet",
    type: EnumWalletItem.okx,
    url: "https://www.okx.com/web3",
  },
  [EnumWalletItem.metamask]: {
    img: "https://assets.outer.gg/frontend/img/common/metamask.svg",
    desc: "MetaMask",
    type: EnumWalletItem.metamask,
    url: "http://metamask.io/",
  },
};

export const mainContentCfg: any = {
  title: "What is a Wallet?",
  content:
    "A wallet is used to send, receive, store, and display digital assets. It's also a new way to log in, without needing to create new accounts and passwords on every website.",
};

export const getWalletCfg: any = {
  title: "Not what you're looking for?",
  content:
    "Select a wallet on the main screen to get started with a different wallet provider.",
};
