import { sepolia, xLayerTestnet, arbitrumSepolia } from "wagmi/chains";

const testCfg = {
  0xaa36a7: {
    ...sepolia,
  },
  0xc3: {
    ...xLayerTestnet,
  },
  0x463: {
    id: 0x463,
    name: "b2_testne",
    nativeCurrency: {
      decimals: 18,
      name: "b2",
      symbol: "b2",
    },
    rpcUrls: {
      default: { http: ["https://b2-testnet.alt.technology"] },
    },
    blockExplorers: {
      default: {
        name: "b2_testne",
        url: "https://b2-testnet.alt.technology",
      },
    },
    contracts: {
      multicall3: {
        address: "",
        blockCreated: 0,
      },
    },
    testnet: true,
  },
  0x66eee: {
    ...arbitrumSepolia,
  },
};
export default testCfg;
