export const chainIcons: any = {
  0xaa36a7: "https://assets.crystalfun.io/frontend/images/eth_icon.png",
  0xc3: "https://assets.outer.gg/frontend/img/common/okxIcon.png",
  0x1: "https://assets.crystalfun.io/frontend/images/eth_icon.png",
  0xc4: "https://assets.outer.gg/frontend/img/common/okxIcon.png",
  0x463: "https://bridge.bsquared.network/assets/icon_btc.svg",
  0xdf: "https://bridge.bsquared.network/assets/icon_btc.svg",
  0x66eee: "https://assets.outer.gg/frontend/img/common/arbitrumIcon.png",
  0xa4b1: "https://assets.outer.gg/frontend/img/common/arbitrumIcon.png",
};
