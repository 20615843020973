import styles from "./index.module.css";

function Dot(props: any) {
  const { isLast, color = "#444" } = props;
  const sty: any = {
    width: "60%",
    height: "60%",
    display: "inline-block",
    position: "absolute",
    top: isLast ? "auto" : 0,
    backgroundColor: color,
    borderRadius: "100%",
    animation: `${styles.skBounce} 2.0s infinite ease-in-out`,
    animationDelay: isLast ? "-1.0s" : "0s",
  };
  if (isLast) {
    sty.bottom = 0;
  }
  return <div style={{ ...sty }} />;
}

export default Dot;
