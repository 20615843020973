import styles from "../index.module.css";

function ContentBlock(props: { title: string; content: string }) {
  const { title, content } = props;
  return (
    <div className={styles.contentBlock}>
      <p className={styles.title}>{title}</p>
      <p className={styles.blockContent}>{content}</p>
    </div>
  );
}

export default ContentBlock;
