import ChainBtn from "./ChainBtn";
import styles from "./index.module.css";
import { getContractCfg } from "../../../config/contract";

function ModalBody(props: any) {
  const { onClose, switchFn } = props;
  const { chains } = getContractCfg();
  return (
    <div className={styles["chain-modal-body"]}>
      <div className={styles["switch-desc"]}>
        This app doesn't support your current network.
        <br />
        Switch to an available option following to continue.
      </div>
      <div className={styles["chain-content"]}>
        {chains.map((item: any) => {
          const { id, name } = item;
          return (
            <ChainBtn
              key={id}
              name={name}
              id={id}
              onClose={onClose}
              switchFn={switchFn}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ModalBody;
