function TwitterSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="svg-black-icon"
    >
      <path d="M21.096 20.8L14.06 10.5424L14.072 10.552L20.416 3.19995H18.296L13.128 9.18395L9.024 3.19995H3.464L10.0328 12.7768L10.032 12.776L3.104 20.8H5.224L10.9696 14.1424L15.536 20.8H21.096ZM8.184 4.79995L18.056 19.2H16.376L6.496 4.79995H8.184Z"></path>
    </svg>
  );
}

export default TwitterSvg;
