import {
  RainbowKitProvider,
  darkTheme,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { mainnet, xLayerTestnet } from "wagmi/chains";
import { WagmiProvider, createConfig, http } from "wagmi";
import { okxWallet, metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import Router from "./router";
import { useEffect } from "react";
import { getContractCfg } from "./config/contract";
import "./App.css";
import "./public.css";
import Wallet from "./Wallet";
// import { showSwitchNetwordModal } from "./components/MyModal/SwithNetworkModal";
import { useTitle } from "ahooks";
import { showJoinSocialModal } from "@/components/MyModal/JoinSocialModal";
import { ConfigProvider, theme } from "antd";

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [okxWallet, metaMaskWallet],
    },
  ],
  {
    appName: "crystalfun",
    projectId: "crystalfun_web",
  }
);

const queryClient = new QueryClient();

const otherContractCfg: any = getContractCfg();

const config = createConfig({
  connectors,
  ...otherContractCfg,
  downloadUrls: {
    android: "https://play.google.com/store/apps/details?id=my.wallet",
    ios: "https://apps.apple.com/us/app/my-wallet",
    chrome: "https://chrome.google.com/webstore/detail/my-wallet",
    qrCode: "https://my-wallet/qr",
  },
  mobile: {
    getUri: (uri: string) => {
      debugger;
    },
  },
});

const App = () => {
  useTitle("Crystal fun | Crystal fun");

  useEffect(() => {
    console.log("------env------", process.env.REACT_APP_ENV, config);
    setTimeout(() => {
      showJoinSocialModal();
    }, 1000);
  }, []);

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize="compact" locale="en" theme={darkTheme()}>
          <ConfigProvider
            theme={{
              algorithm: theme.darkAlgorithm,
            }}
          >
            <>
              <Router />
              <Wallet />
            </>
          </ConfigProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
export default App;
