import { Popup } from "antd-mobile";
import styles from "./index.module.css";
import { useState } from "react";
import GetWallet from "./GetWallet";
import MainView from "./MainView";
import CommonTitle from "../components/CommonTitle";

function Mobilepopup(props: { show: boolean; onClose: () => void }) {
  const { show, onClose } = props;
  const [isGetWallet, setIsGetWallet] = useState(false);

  return (
    <Popup
      visible={show}
      onMaskClick={() => {
        onClose();
      }}
      bodyStyle={{
        borderTopLeftRadius: "18px",
        borderTopRightRadius: "18px",
        minHeight: "40vh",
        backgroundColor: "#24262a",
      }}
    >
      <div className={styles.noWalletPopup}>
        <div className={styles.titleDiv}>
          <CommonTitle
            title="Connect a Wallet"
            showBack={isGetWallet}
            onClose={onClose}
            onBack={() => {
              setIsGetWallet(false);
            }}
          />
        </div>

        {!isGetWallet ? (
          <MainView
            onGetWallet={() => {
              setIsGetWallet(true);
            }}
          />
        ) : (
          <GetWallet />
        )}
      </div>
    </Popup>
  );
}

export default Mobilepopup;
