import copy from "copy-to-clipboard";
import styles from "./index.module.css";
import { showToast } from "@/components/showToast";
import { COMMON_TOAST_TYPE } from "@/components/showToast/constance";
// import showToast, {
//   COMMON_TOAST_TYPE_SUCCESS,
//   COMMON_TOAST_TYPE_WARNNING,
// } from "src/components/commonToast/showToast";

function InviteContentListItem(props: any) {
  const { activeAddress, code, ownerAddress } = props;
  const url = `${window.location.origin}/invited/${code}`;

  function onCopy() {
    if (activeAddress) return;
    copy(url);
    // showToast({ type: COMMON_TOAST_TYPE_SUCCESS, content: "Copy success" });
    showToast("Copy success");
  }

  return (
    <div className={styles["inv-content-list"]}>
      <div className={styles["list-front"]}>
        <div className={styles["username-avatar"]}>
          <img
            className={styles["username-avatar-img"]}
            src="https://assets.crystalfun.io/frontend/images/headIcon.png"
            alt=""
          />
        </div>
        <div
          className={
            activeAddress ? styles["codenumber-grey"] : styles[`codenumber`]
          }
        >
          {activeAddress ? `Used address:${activeAddress}` : `${code}`}
        </div>
      </div>
      <div className={styles["list-end"]}>
        <div className={styles["link-addr"]}>{activeAddress ? "" : url}</div>
        <div className={styles["list-btn"]}>
          <button
            type="button"
            className={styles["copy-btn"]}
            disabled={activeAddress}
            onClick={onCopy}
          >
            <div className={styles["copy-btn-text"]}>
              {activeAddress ? "Used" : "Copy"}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default InviteContentListItem;
